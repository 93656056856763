
* {
  box-sizing: border-box;
}
body,html,#root,.App{
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.visible-on-hover {
  opacity: 0;
  transition: all 0.5s;

  &:hover {
    opacity: 1;
  }
}
